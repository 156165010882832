import { AppConfig } from "./types";

const configs: Record<string, AppConfig> = {
	staging_za: {
		aws_url: "https://api.xplan-outlook-plugin.aws-wealth-staging-za.iress.online/lambda",
		redirect_url:
			"https://xplan-outlook-plugin.aws-wealth-staging-za.iress.online/callback.html",
		bucket_name: "xplan-outlook-plugin.aws-wealth-staging-za.iress.online",
		app_client_id: "0gCk1iPOQXhPsgSaHMuI",
		environment: "staging_za",
		awsRegion: "eu-west-1",
		hidden_urls: {
			Internal_Test: {
				url: "https://wakanda.xplan.iress.co.za",
				redirect: "https://zabetacloud.xplan.aws-wealth-staging-za.iress.online",
			},
		},
		sso_enabled_sites: [],
	},
	uat_za: {
		aws_url: "https://api.xplan-outlook-plugin.aws-wealth-uat-za.iress.online/lambda",
		redirect_url: "https://xplan-outlook-plugin.aws-wealth-uat-za.iress.online/callback.html",
		app_client_id: "wmVLfjNMhOCogpOpKfHi",
		environment: "uat_za",
		awsRegion: "eu-west-1",
		bucket_name: "xplan-outlook-plugin.aws-wealth-uat-za.iress.online",
		hidden_urls: {
			Internal_Test: {
				url: "https://wakanda.xplan.iress.co.za",
				redirect: "",
			},
		},
		sso_enabled_sites: [],
	},
	production_za: {
		aws_url: "https://api.outlookplugin.iress.co.za/lambda",
		redirect_url: "https://outlookplugin.iress.co.za/callback.html",
		app_client_id: "TT05MsAYQG0dTNY7pUXC",
		environment: "production-za",
		awsRegion: "eu-west-1",
		bucket_name: "outlookplugin.iress.co.za",
		hidden_urls: {
			Sanlam_Prod: {
				url: "https://sanfin.sanlamadvice.co.za/sanfin",
				redirect: "https://sanfin.sanlamadvice.co.za/sanfin",
			},
			Sanlam_PPE: {
				url: "https://sanfin-ppe.sanlamadvice.co.za/sanfin",
				redirect: "https://sanfin-ppe.sanlamadvice.co.za/sanfin",
			},
			Sanlam_TST: {
				url: "https://sanfin-tst.sanlamadvice.co.za/sanfin-tst",
				redirect: "https://sanfin-tst.sanlamadvice.co.za/sanfin-tst",
			},
			Sanlam_Prod_Nam: {
				url: "https://sanfinnam.sanlamadvice.co.za/sanfinnam",
				redirect: "https://sanfinnam.sanlamadvice.co.za/sanfinnam",
			},
			Sanlam_PPE_Nam: {
				url: "https://sanfinnam-ppe.sanlamadvice.co.za/sanfinnam",
				redirect: "https://sanfinnam-ppe.sanlamadvice.co.za/sanfinnam",
			},
			Sanlam_TST_Nam: {
				url: "https://sanfinnam-tst.sanlamadvice.co.za/sanfinnam",
				redirect: "https://sanfinnam-tst.sanlamadvice.co.za/sanfinnam",
			},
			Internal_Test: {
				url: "https://wakanda.xplan.iress.co.za",
				redirect: "https://wakanda.xplan.iress.co.za",
			},
		},
		sso_enabled_sites: [],
	},
	production_ca: {
		aws_url: "https://api.xplan-outlook-plugin.aws-wealth-production-ca.iress.online/lambda",
		redirect_url:
			"https://xplan-outlook-plugin.aws-wealth-production-ca.iress.online/callback.html",
		app_client_id: "QBdl2J3t0piaLLpCYOms",
		environment: "production-ca",
		awsRegion: "ca-central-1",
		bucket_name: "xplan-outlook-plugin.aws-wealth-production-ca.iress.online",
		hidden_urls: {},
		sso_enabled_sites: [],
	},
	production_uk: {
		aws_url: "https://api.outlookplugin.iress.co.uk/lambda",
		redirect_url: "https://outlookplugin.iress.co.uk/callback.html",
		app_client_id: "XCpCGiKQkDLgDGD8WRsM",
		environment: "production_uk",
		awsRegion: "eu-west-2",
		bucket_name: "outlookplugin.iress.co.uk",
		hidden_urls: {
			Internal_Test: {
				url: "https://wakanda.xplan.iress.co.za",
				redirect: "https://wakanda.xplan.iress.co.za",
			},
		},
		sso_enabled_sites: [],
	},
	production_au: {
		aws_url: "https://api.outlookplugin.iress.com.au/lambda",
		redirect_url: "https://outlookplugin.iress.com.au/callback.html",
		app_client_id: "QBdl2J3t0piaLLpCYOms",
		environment: "production_au",
		awsRegion: "ap-southeast-2",
		bucket_name: "outlookplugin.iress.com.au",
		hidden_urls: {
			Internal_Test: {
				url: "https://wakanda.xplan.iress.co.za",
				redirect: "https://wakanda.xplan.iress.co.za",
			},
		},
		sso_enabled_sites: [
			"https://modeloffice.xplan.iress.com.au",
			"https://modelofficetest.xplan.iress.com.au",
			"https://modelofficedemo.xplan.iress.com.au",
			"https://accountexecsfour.xplan.iress.com.au",
			"https://managed.xplan.iress.com.au",
			"https://iressdirect.xplan.iress.com.au",
			"https://trainingdemo.xplan.iress.com.au",
			"https://plannercentral.xplan.iress.com.au",
			"https://plannercentraltest.xplan.iress.com.au",
		  ],
	},
};

function getEnvironment(): string {
	const url = document.URL;
	if (url.includes(configs["production_za"].bucket_name)) return "production_za";
	else if (url.includes(configs["production_uk"].bucket_name)) return "production_uk";
	else if (url.includes(configs["production_ca"].bucket_name)) return "production_ca";
	else if (url.includes(configs["production_au"].bucket_name)) return "production_au";
	else if (url.includes(configs["uat_za"].bucket_name)) return "uat_za";
	else return "staging_za";
}

export function getAppConfig(): AppConfig {
	const environment = getEnvironment();
	return configs[environment];
}
